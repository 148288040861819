import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Card, Checkbox, Col, Input, Row, Select } from 'antd';

import Uploader from '../components/ui/Uploader';

const EditCar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const carId = location.pathname.split('/').pop();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [formImg, setFormImg] = useState(null);
  const [formData, setFormData] = useState({});


  const fetchCarDetails = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars/${carId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!res.ok) {
        setError(`Failed to fetch car: ${res.status} ${res.statusText}`)
        throw new Error(`Failed to fetch car: ${res.status} ${res.statusText}`);
      }

      const { data } = await res.json();
      setFormData(data);

    } catch (error) {
      setLoading(false);
      console.error("Error fetching car", error.message);
      setError(`Error fetching car: ${error.message}. Please try again.`);
    }
  };

  useEffect(() => {
    fetchCarDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
      const res = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
        method: 'POST',
        body: formImg,
      });

      const result = await res.json();

      const updatedData = {
        ...formData,
        imgCar: result.error ? { url: formData.imgCar?.url, public_id: formData.imgCar.public_id } : { url: result?.secure_url, public_id: result?.public_id },
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars/${carId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
        credentials: 'include',
      });

      if (!response.ok) {
        setError(`Failed to update car: ${res.status} ${res.statusText}`)
        throw new Error(`Failed to update car: ${response.status} ${response.statusText}`);
      }

      setFormImg(null);
      fetchCarDetails();

      setTimeout(() => {
         setSuccess("Car has been updated successfully !");
        setTimeout(() => {
          setSuccess(null);
          setTimeout(() => {
            setLoading(false);
            navigate('/list-of-cars')
          }, 2500)
        }, 2000)
      }, 1000)

    } catch (error) {
      setLoading(false);
      console.error("Error updating car", error.message);
      setError(`Error updating car: ${error.message}. Please try again.`);
    }
  };

  const handleCancel = () => {
    navigate("/list-of-cars");
  };

  return (
    <section className='edit-car__section'>
      <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
      <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />

      <div className="container mx-auto">
        <div className='relative bg-foreground p-7 rounded-xl overflow-hidden'>
          <div className='flex gap-5 mb-5'>
            <Row gutter={10}>
              <Col span={8}>
                <Card bordered={false}>
                  <Uploader setFormData={setFormImg} removeSelectedImage={false} carImg={formData.imgCar} />
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-20 text-copy-dark text-sm leading-tight'>Make:</span>
                    <Input
                      value={formData.make || ''}
                      onChange={(e) => setFormData({ ...formData, make: e.target.value })}
                      placeholder="Chevrolet"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-20 text-copy-dark text-sm leading-tight'>Model:</span>
                    <Input
                      value={formData.model || ''}
                      onChange={(e) => setFormData({ ...formData, model: e.target.value })}
                      placeholder="Gentra"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                </Card>
              </Col>
              <Col span={16}>
                <Card bordered={false}>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Color:</span>
                    <Input
                      value={formData.color || ''}
                      onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                      placeholder="White"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Seat:</span>
                    <Input
                      value={formData.seat || ''}
                      onChange={(e) => setFormData({ ...formData, seat: e.target.value })}
                      placeholder="0"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Door:</span>
                    <Input
                      value={formData.door || ''}
                      onChange={(e) => setFormData({ ...formData, door: e.target.value })}
                      placeholder="0"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Baggage:</span>
                    <Input
                      value={formData.baggage || ''}
                      onChange={(e) => setFormData({ ...formData, baggage: e.target.value })}
                      placeholder="0"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Price per hours:</span>
                    <Input
                      value={formData.pricePerHour || ''}
                      onChange={(e) => setFormData({ ...formData, pricePerHour: e.target.value })}
                      placeholder="0"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Price per day:</span>
                    <Input
                      value={formData.pricePerDay || ''}
                      onChange={(e) => setFormData({ ...formData, pricePerDay: e.target.value })}
                      placeholder="0"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Deposit:</span>
                    <Input
                      value={formData.deposit || ''}
                      onChange={(e) => setFormData({ ...formData, deposit: e.target.value })}
                      placeholder="0"
                      className='disabled:bg-foreground text-copy-dark'
                    />
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Type of car:</span>
                    <Select
                      onChange={(value) => setFormData({ ...formData, typeOfCar: value })}
                      value={formData.typeOfCar || ''}
                      className='w-full block'
                    >
                      <Select.Option value="sedan">Sedan</Select.Option>
                      <Select.Option value="electric">Electric</Select.Option>
                      <Select.Option value="hybrid">Hybrid</Select.Option>
                    </Select>
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Category of car:</span>
                    <Select
                      onChange={(value) => setFormData({ ...formData, category: value })}
                      value={formData.category || ''}
                      className='w-full block'
                    >
                      <Select.Option value="sport">Sport</Select.Option>
                      <Select.Option value="luxury">Luxury</Select.Option>
                      <Select.Option value="compact">Compact</Select.Option>
                      <Select.Option value="standard">Standard</Select.Option>
                      <Select.Option value="minivan">Minivan</Select.Option>
                      <Select.Option value="pickup-truck">Pickup Truck</Select.Option>
                    </Select>
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <span className='w-48 text-copy-dark text-sm leading-tight'>Transmission:</span>
                    <Select
                      onChange={(value) => setFormData({ ...formData, transmission: value })}
                      value={formData.transmission || ''}
                      className='w-full block'
                    >
                      <Select.Option value="mechanic">Mechanic</Select.Option>
                      <Select.Option value="automatic">Automatic</Select.Option>
                    </Select>
                  </div>

                  <div className='mt-2.5 flex items-center gap-3'>
                    <Checkbox
                      checked={formData.mileageLimit?.limited || false}
                      onChange={(e) => setFormData({ ...formData, mileageLimit: { ...formData.mileageLimit, limited: e.target.checked } })}
                      className='w-48'
                    >Limited</Checkbox>
                    {
                      formData.mileageLimit?.limited && <Input
                        value={formData.mileageLimit?.maxMileage || ''}
                        onChange={(e) => setFormData({ ...formData, mileageLimit: { ...formData.mileageLimit, maxMileage: e.target.value } })}
                        placeholder="200$"
                        className='w-full'
                      />
                    }
                  </div>

                  <div className='mt-2.5 flex items-center gap-3'>
                    <Checkbox
                      checked={formData.options?.returnLocationFees || false}
                      onChange={(e) => setFormData({ ...formData, options: { ...formData.options, returnLocationFees: e.target.checked } })}
                      className='w-48'
                    >Return to location fees</Checkbox>
                    {
                      formData.options?.returnLocationFees && <Input
                        value={formData.options.returnLocationFeesPrice || ''}
                        onChange={(e) => setFormData({ ...formData, options: { ...formData.options, returnLocationFeesPrice: e.target.value } })}
                        placeholder="200$"
                        className='w-full'
                      />
                    }
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <Checkbox
                      checked={formData.options?.kidsSeat || false}
                      onChange={(e) => setFormData({ ...formData, options: { ...formData.options, kidsSeat: e.target.checked } })}
                      className='w-48'
                    >Kids seat</Checkbox>
                    {
                      formData.options?.kidsSeat && <Input
                        value={formData.options?.kidsSeatPrice || ''}
                        onChange={(e) => setFormData({ ...formData, options: { ...formData.options, kidsSeatPrice: e.target.value } })}
                        placeholder="200$"
                        className='w-full'
                      />
                    }
                  </div>
                  <div className='mt-2.5 flex items-center gap-3'>
                    <Checkbox
                      checked={formData.options?.deliveryFees || false}
                      onChange={(e) => setFormData({ ...formData, options: { ...formData.options, deliveryFees: e.target.checked } })}
                      className='w-48'
                    >Delivery fees</Checkbox>
                    {
                      formData.options?.deliveryFees && <Input
                        value={formData.options.deliveryFeesPrice || ''}
                        onChange={(e) => setFormData({ ...formData, options: { ...formData.options, deliveryFeesPrice: e.target.value } })}
                        placeholder="200$"
                        className='w-full'
                      />
                    }
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="flex justify-center gap-3 mt-5">
            <button
              type="submit"
              disabled={loading}
              className="w-fit py-[2vw] sm:py-3.5 px-[5vw] sm:px-8 rounded-[1.5vw] sm:rounded-xl bg-primary hover:bg-background text-copy-light hover:text-primary text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-border hover:border-primary disabled:animate-pulse transition-all duration-[0.3s] ease-in-out"
              onClick={handleSubmit}
            >
              Update
            </button>
            <button
              type="button"
              disabled={loading}
              onClick={handleCancel}
              className="w-fit py-[2vw] sm:py-3.5 px-[5vw] sm:px-8 rounded-[1.5vw] sm:rounded-xl bg-error hover:bg-background text-copy-light hover:text-error text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-error disabled:animate-pulse transition-all duration-[0.3s] ease-in-out"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCar;