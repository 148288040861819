import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react";
import { fetchCars } from "./redux/slice/fetchCarSlice"
import { fetchBooking } from "./redux/slice/fetchBookingSlice";

import Analitics from "./components/ui/Analitics";
import Cards from "./components/ui/Cards";
import Chart from "./components/ui/Chart";

function App() {

  const dispatch = useDispatch();
    const { cars, status: carStatus, error: carError } = useSelector(state => state.cars);
    const { bookings, status: bookingStatus, error: bookingError } = useSelector(state => state.bookings);
    
    useEffect(() => {
        if (carStatus === 'idle') {
            dispatch(fetchCars());
        }
        if (bookingStatus === 'idle') {
            dispatch(fetchBooking());
        }
    }, [dispatch, carStatus, bookingStatus]);

  return (
    <main className="px-7">
      <Cards bookings={bookings} />
      <Analitics cars={cars} bookings={bookings} bookingStatus={bookingStatus} carStatus={carStatus} carError={carError} bookingError={bookingError} />
      <Chart cars={cars} bookings={bookings} bookingStatus={bookingStatus} carStatus={carStatus} carError={carError} bookingError={bookingError} />
    </main>
  );
}

export default App;
