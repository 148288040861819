import React, { useState } from 'react';
import { Alert, Button, Checkbox, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Uploader from '../components/ui/Uploader';
import { useNavigate } from 'react-router-dom';


const AddCar = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [removeSelectedImage, setRemoveSelectedImage] = useState(false);
    const [formImg, setFormImg] = useState(null);

    const [form] = Form.useForm();
    const [limited, setLimited] = useState(false);
    const [returnLocationFees, setReturnLocationFees] = useState(false);
    const [kidsSeat, setKidsSeat] = useState(false);
    const [deliveryFees, setDeliveryFees] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const cloundName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
            const result = await fetch(`https://api.cloudinary.com/v1_1/${cloundName}/image/upload`, {
                method: 'POST',
                body: formImg
            }).then(res => res.json());

            if (!result.secure_url) {
                throw new Error('Image upload failed');
            }

            const data = { ...values, imgCar: { url: result?.secure_url, public_id: result?.public_id } }

            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include',
            });

            if (!res.ok) {
                setError(`Failed to create car: ${res.status} ${res.statusText}`)
                throw new Error(`Failed to create car: ${res.status} ${res.statusText}`);
            }

            setFormImg(null);
            setRemoveSelectedImage(null)

            setTimeout(() => {
                setSuccess("Car has been created successfully !");
                setTimeout(() => {
                    setSuccess(null);
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/list-of-cars')
                    }, 2500)
                }, 2000)
            }, 1000)
        } catch (error) {
            setLoading(false);
            console.error("Error creating car", error.message);
            setError(`Error creating car: ${error.message}. Please try again.`);
        }

    };


    return (
        <section className='add-car__section'>
            <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            
            <div className="container mx-auto">
                <div className='max-w-4xl mx-auto px-7 py-14 bg-foreground rounded-xl'>
                    <div className='max-w-md mx-auto h-72 mb-10'>
                        <Uploader setFormData={setFormImg} removeSelectedImage={removeSelectedImage} />
                    </div>
                    <Form
                        onFinish={onFinish}
                        {...formItemLayout}
                        variant="filled"
                        form={form}
                    >
                        <Form.Item hasFeedback name="make" validateDebounce={1000} label="Make">
                            <Input placeholder="Chevrolet" />
                        </Form.Item>
                        <Form.Item hasFeedback name="model" validateDebounce={1000} label="Model">
                            <Input placeholder="Gentra" />
                        </Form.Item>
                        <Form.Item hasFeedback name="typeOfCar" validateDebounce={1000} label="Select type of car">
                            <Select>
                                <Select.Option value="sedan">Sedan</Select.Option>
                                <Select.Option value="electric">Electric</Select.Option>
                                <Select.Option value="hybrid">Hybrid</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item hasFeedback name="category" validateDebounce={1000} label="Select category of car">
                            <Select>
                                <Select.Option value="sport">Sport</Select.Option>
                                <Select.Option value="luxury">Luxury</Select.Option>
                                <Select.Option value="compact">Compact</Select.Option>
                                <Select.Option value="standard">Standard</Select.Option>
                                <Select.Option value="minivan">Minivan</Select.Option>
                                <Select.Option value="pickup-truck">Pickup Truck</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item hasFeedback name="color" validateDebounce={1000} label="Select color of car">
                            <Select>
                                <Select.Option value="red">Red</Select.Option>
                                <Select.Option value="gray">Gray</Select.Option>
                                <Select.Option value="white">White</Select.Option>
                                <Select.Option value="black">Black</Select.Option>
                                <Select.Option value="orange">Orange</Select.Option>
                                <Select.Option value="yellow">Yellow</Select.Option>
                                <Select.Option value="silver">Silver</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item hasFeedback name="transmission" validateDebounce={1000} label="Select transmission">
                            <Select>
                                <Select.Option value="mechanic">Mechanic</Select.Option>
                                <Select.Option value="automatic">Automatic</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ flex: "1" }} hasFeedback name="seat" validateDebounce={1000} label="Seat">
                            <Input placeholder='0' />
                        </Form.Item>
                        <Form.Item style={{ flex: "1" }} hasFeedback name="door" validateDebounce={1000} label="Door">
                            <Input placeholder='0' />
                        </Form.Item>
                        <Form.Item style={{ flex: "1" }} hasFeedback name="baggage" validateDebounce={1000} label="Baggage">
                            <Input placeholder='0' />
                        </Form.Item>
                        <Form.Item hasFeedback name="pricePerHour" validateDebounce={1000} label="Price per hour">
                            <Input placeholder="20$" />
                        </Form.Item>
                        <Form.Item hasFeedback name="pricePerDay" validateDebounce={1000} label="Price per day">
                            <Input placeholder="2$" />
                        </Form.Item>
                        <Form.Item hasFeedback name="deposit" validateDebounce={1000} label="Deposit">
                            <Input placeholder="200$" />
                        </Form.Item>
                        <Form.Item label="Mileage limit" name="limited">
                            <div className='flex items-center'>
                                <Checkbox
                                    checked={limited}
                                    onChange={(e) => setLimited(e.target.checked)}
                                >Limited</Checkbox>
                                {
                                    limited && <Input placeholder="200$" />
                                }
                            </div>
                        </Form.Item>
                        <Form.Item label="Return location fees" name="returnLocationFees">
                            <div className='flex items-center'>
                                <Checkbox
                                    checked={returnLocationFees}
                                    onChange={(e) => setReturnLocationFees(e.target.checked)}
                                >Limited</Checkbox>
                                {
                                    returnLocationFees && <Input placeholder="200$" />
                                }
                            </div>
                        </Form.Item>
                        <Form.Item label="Kids seat" name="kidsSeat">
                            <div className='flex items-center'>
                                <Checkbox
                                    checked={kidsSeat}
                                    onChange={(e) => setKidsSeat(e.target.checked)}
                                >Limited</Checkbox>
                                {
                                    kidsSeat && <Input placeholder="200$" />
                                }
                            </div>
                        </Form.Item>
                        <Form.Item label="Delivery Fees" name="deliveryFees">
                            <div className='flex items-center'>
                                <Checkbox
                                    checked={deliveryFees}
                                    onChange={(e) => setDeliveryFees(e.target.checked)}
                                >Limited</Checkbox>
                                {
                                    deliveryFees && <Input placeholder="200$" />
                                }
                            </div>
                        </Form.Item>
                        <Form.List name="features">
                            {(fields, { add, remove }) => (
                                <div className='flex flex-col gap-3'>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} className='flex justify-center items-center'>
                                            <Form.Item
                                                style={{ marginBottom: '0px', width: '100%' }}
                                                {...restField}
                                                name={[name, 'item']}
                                                fieldKey={[name, 'item']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Feature name is required',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Feature Name" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Field
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                        <div className='flex justify-center mt-5'>
                            <Button disabled={loading} type="primary" htmlType="submit" className='disabled:animate-pulse'>
                                Add Car
                            </Button>
                        </div>
                    </Form>
                </div>

            </div >
        </section >
    )
}
export default AddCar;