import { configureStore } from '@reduxjs/toolkit';
import fetchCarReducer from './slice/fetchCarSlice';
import fetchBookingReducer from './slice/fetchBookingSlice';

const store = configureStore({
  reducer: {
    cars: fetchCarReducer,
    bookings: fetchBookingReducer,
  },
});

export default store;
