import { Car } from "../../icons/icons";

const Analitics = ({ cars, bookings, bookingStatus, carStatus, carError, bookingError }) => {
    
    // Compute statistics for cars
    const availableCarsCount = cars?.filter(car => car.availabilityStatus === 'available').length;
    const unavailableCarsCount = cars?.filter(car => car.availabilityStatus === 'unavailable').length;


    // Compute statistics for bookings
    const statusCounts = bookings?.reduce((acc, booking) => {
        const status = booking.rentcarStatus;
        acc[status] = (acc[status] || 0) + 1;
        return acc;
    }, {});

    const statusLabels = ["pending", "confirmed", "cancelled", "completed", "rented", "returned", "rejected"];

    return (
        <>
            <div className='grid grid-flow-row grid-cols-4 gap-5'>
                <div className='p-5 bg-white flex-center gap-5 border border-border rounded-3xl shadow-sm'>
                    <div className='relative w-20 h-20 p-5 border-2 border-blue-600 rounded-full overflow-hidden'>
                        <Car className="w-10 h-10 fill-blue-600 stroke-blue-900" />
                    </div>
                    <div className='flex flex-col'>
                        <h4 className='text-copy-lighter text-xl font-bold leading-normal'>Available Cars</h4>
                        <span className='text-copy text-3xl font-black leading-tight'>
                            {carStatus === 'loading' ? 'Loading...' : carStatus === 'failed' ? carError : `${unavailableCarsCount}/${availableCarsCount}`}
                        </span>
                    </div>
                </div>
                {statusLabels.map(status => (
                    <div key={status} className='p-5 bg-white flex-center gap-5 border border-border rounded-3xl shadow-sm'>
                        <div className='relative w-20 h-20 p-5 border-2 border-blue-600 rounded-full overflow-hidden'>
                            <Car className="w-10 h-10 fill-blue-600 stroke-blue-900" />
                        </div>
                        <div className='flex flex-col'>
                            <h4 className='text-copy-lighter text-xl font-bold leading-normal'>{status.charAt(0).toUpperCase() + status.slice(1)}</h4>
                            <span className='text-copy text-3xl font-black leading-tight'>
                                {bookingStatus === 'loading' ? 'Loading...' : bookingStatus === 'failed' ? bookingError : statusCounts[status] || 0}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Analitics