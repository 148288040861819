import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define an initial state
const initialState = {
  bookings: [],
  status: "idle",
  error: null,
};

// Define an async thunk for fetching bookings
export const fetchBooking = createAsyncThunk(
  "bookings/fetchBookings",
  async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}booking`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    const data = await response.json();
    return data;
  }
);

// Create the slice
const fetchBookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bookings = action.payload;
      })
      .addCase(fetchBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default fetchBookingSlice.reducer;
