import { jwtDecode } from "jwt-decode";

export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  } catch (error) {
    console.error("Token decoding failed", error);
    return false;
  }
};

export const getStatusClassName = (status) => {
  switch (status) {
    case "active":
      return "bg-success";
    case "accepted":
      return "bg-info";
    case "rejected":
      return "bg-error";
    case "in-progress":
      return "bg-warning";
    default:
      return "bg-primary";
  }
};
