import { useEffect, useState } from "react"

import { Alert, Button, Space, Table } from "antd";
import { Trush, View } from "../icons/icons";

const ListOfCars = () => {

    const [listOfCars, setListOfCars] = useState(null);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const columns = [
        {
            title: 'Make',
            width: 150,
            dataIndex: 'make',
            fixed: 'left',

        },
        {
            title: 'Model',
            width: 150,
            dataIndex: 'model',
            fixed: 'left',
        },
        {
            title: 'Type of car',
            dataIndex: 'typeOfCar',
            width: 150,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            width: 150,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            width: 150,
        },
        {
            title: 'Transmission',
            dataIndex: 'transmission',
            width: 150,
        },
        {
            title: 'Deposit',
            dataIndex: 'deposit',
            width: 150,
        },
        {
            title: "Status",
            dataIndex: "",
            width: 150,
            render: (item) => (
                <Button className={`w-full py-5 ${item.availabilityStatus === "available" ? "bg-blue-300" : "bg-red-300"}`} onClick={() => handleChangeStatus(item._id, item.availabilityStatus)}>
                    <Space className="w-full flex justify-center">
                        {item.availabilityStatus}
                    </Space>
                </Button>
            ),
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 150,
            render: (item) => (
                <div className="flex justify-center items-center gap-5">
                    <button onClick={() => handleDeleteCar(item._id)} className="p-2.5 rounded-xl border border-border">
                        <Trush className="w-5 h-5 fill-red-600" />
                    </button>
                    <div className='p-2.5 rounded-xl border border-border'>
                        <a href={`/list-of-cars/${item._id}`}>
                            <View className="w-5 h-5 fill-green-600" />
                        </a>
                    </div>
                </div>
            ),
        },
    ];

    const fetchCars = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                setError(`Failed to fetch cars: ${response.status} ${response.statusText}`)
                throw new Error(`Failed to fetch cars: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            setLoading(false);
            setListOfCars(data);

        } catch (error) {
            setLoading(false)
            console.error('Error fetching cars:', error.message);
            setError(`Error fetching cars: ${error.message}. Please try again.`);
        }
    };


    useEffect(() => {
        fetchCars()
    }, [])

    const handleDeleteCar = async (carId) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars/${carId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                setError(`Failed to delete cars: ${response.status} ${response.statusText}`)
                throw new Error(`Failed to delete cars: ${response.status} ${response.statusText}`);
            }

            const updatedCars = listOfCars.filter(car => car._id !== carId);
            setListOfCars(updatedCars);

            setTimeout(() => {
                setSuccess("Cars has been deleted successfully !");
                setTimeout(() => {
                    setSuccess(null);
                    fetchCars();
                }, 2000)
            }, 1000)

        } catch (error) {
            setLoading(false);
            console.error('Error deleting car:', error.message);
            setError(`Error deleting cars: ${error.message}. Please try again.`);
        }
    };

    const handleChangeStatus = async (id, status) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ availabilityStatus: status === "available" ? "unavailable" : "available" }),
                credentials: 'include',
            });

            if (!res.ok) {
                setError(`Failed to edit car's status: ${res.status} ${res.statusText}`)
                throw new Error(`Failed to edit status: ${res.status} ${res.statusText}`);
            }

            const updatedListOfCars = listOfCars.filter(status => status._id !== id);
            setListOfCars(updatedListOfCars);

            setTimeout(() => {
                setSuccess("Car's status has been updated successfully !");
                setTimeout(() => {
                    setSuccess(null);
                }, 2000)
            }, 1000)

        } catch (error) {
            console.error("Error updating car's status", error.message);
            setError(`Error updating car's status: ${error.message}. Please try again.`);
        }
    }

    return (
        <section className='list-of-cars__section'>
            <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <Alert message={error} type="success" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <div className="main-container">

                <div className='flex justify-end items-center mb-10'>
                    <a href="/add-car">
                        <button className='py-2.5 px-20 bg-primary rounded-md border border-border text-white font-bold cursor-pointer'>Add Car</button>
                    </a>
                </div>
                {
                    !loading &&
                    <Table
                        columns={columns}
                        dataSource={listOfCars}
                        bordered
                        size="middle"
                    />
                }
            </div>
        </section>
    )
}

export default ListOfCars