import { useEffect, useState } from 'react'
import { Cash, Signal } from '../../icons/icons'
import moment from 'moment';

const Cards = ({ bookings }) => {
    const [thisMonthEarnings, setThisMonthEarnings] = useState(0);
    const [lastMonthEarnings, setLastMonthEarnings] = useState(0);

    useEffect(() => {
        const now = moment();
        const startOfMonth = now.startOf('month').toDate();
        const startOfLastMonth = now.subtract(1, 'month').startOf('month').toDate();
        const endOfLastMonth = now.endOf('month').toDate();

        const completedBookings = bookings?.filter(booking => booking.rentcarStatus === 'completed') || [];

        const thisMonth = completedBookings.filter(booking => {
            const createdAt = new Date(booking.createdAt);
            return createdAt >= startOfMonth;
        });

        const lastMonth = completedBookings.filter(booking => {
            const createdAt = new Date(booking.createdAt);
            return createdAt >= startOfLastMonth && createdAt <= endOfLastMonth;
        });

        const calculateEarnings = (bookings) => bookings.reduce((acc, booking) => acc + booking.totalCost, 0);

        setThisMonthEarnings(calculateEarnings(thisMonth));
        setLastMonthEarnings(calculateEarnings(lastMonth));

    }, [bookings]);

    const percentageChange = lastMonthEarnings > 0 
        ? ((thisMonthEarnings - lastMonthEarnings) / lastMonthEarnings) * 100 
        : (thisMonthEarnings > 0 ? 100 : 0);

    return (
        <div className="grid gap-y-10 gap-x-6 grid-cols-1 xl:grid-cols-2 mb-5">
            <div className="bg-white rounded-2xl shadow-sm p-3">
                <div className='flex justify-between py-10 px-5'>
                    <div className='relative w-20 h-20 p-5 bg-blue-400 rounded-3xl shadow-md'>
                        <Cash className="w-10 h-10 fill-white" />
                    </div>
                    <div className="p-4 text-right">
                        <p className="block text-copy-dark text-sm leading-normal font-normal">This Month's Earnings</p>
                        <h4 className="block text-copy-dark text-3xl leading-normal font-bold">${thisMonthEarnings}</h4>
                    </div>
                </div>
                <div className="border-t border-blue-gray-50 p-4">
                    <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                        <strong className="text-green-500">{percentageChange.toFixed(2)}%</strong>&nbsp;than last month
                    </p>
                </div>
            </div>
            <div className="bg-white rounded-2xl shadow-sm p-3">
                <div className='flex justify-between py-10 px-5'>
                    <div className='relative w-20 h-20 p-5 bg-orange-400 rounded-3xl shadow-md'>
                        <Signal className="w-10 h-10 fill-white" />
                    </div>
                    <div className="p-4 text-right">
                        <p className="block text-copy-dark text-sm leading-normal font-normal">Last Month's Earnings</p>
                        <h4 className="block text-copy-dark text-3xl leading-normal font-bold">${lastMonthEarnings}</h4>
                    </div>
                </div>
                <div className="border-t border-blue-gray-50 p-4">
                    <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                        <strong className="text-green-500">{percentageChange.toFixed(2)}%</strong>&nbsp;change from last month
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Cards