import { useEffect, useState } from 'react'
import { Alert, Checkbox, Table } from "antd";
import { Trush, View } from "../icons/icons";

const ListOfAdmins = () => {
  const [admins, setAdmins] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);


  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      width: 150,

    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      width: 150,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: 150,
    },
    {
      title: "Permissions",
      dataIndex: "",
      width: 150,
      render: (item) => (
        <div className="flex gap-5">
          <Checkbox
            checked={item.permissions?.read}
            onChange={(e) => handleChangePermission(item._id, { ...item.permissions, read: item.permissions.read === true ? false : true })}
          >
            Read
          </Checkbox>
          <Checkbox
            checked={item.permissions?.write}
            onChange={(e) => handleChangePermission(item._id, { ...item.permissions, write: item.permissions.read === true ? false : true })}
          >
            Write
          </Checkbox>
          <Checkbox
            checked={item.permissions?.delete}
            onChange={(e) => handleChangePermission(item._id, { ...item.permissions, delete: item.permissions.read === true ? false : true })}
          >
            Delete
          </Checkbox>
        </div>

      ),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 150,
      render: (item) => (
        <div className="flex justify-center items-center gap-5">
          <button onClick={() => handleDeleteAdmin(item._id)} className="p-2.5 rounded-xl border border-border">
            <Trush className="w-5 h-5 fill-red-600" />
          </button>
          <div className='p-2.5 rounded-xl border border-border'>
            <a href={`/list-of-admins/${item._id}`}>
              <View className="w-5 h-5 fill-green-600" />
            </a>
          </div>
        </div>
      ),
    },
  ];


  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}admins`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        setError(`Failed to fetch admins: ${response.status} ${response.statusText}`)
        throw new Error(`Failed to fetch admins: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setLoading(false);
      setAdmins(data);

    } catch (error) {
      setLoading(false);
      console.error('Error fetching offers:', error.message);
      setError(`Error fetching cars: ${error.message}. Please try again.`);
    }
  };

  useEffect(() => {
    fetchAdmins()
  }, [])

  const handleDeleteAdmin = async (adminId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}admins/${adminId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        setError(`Failed to delete admin: ${response.status}`)
        throw new Error(`Failed to delete admin: ${response.status} ${response.statusText}`);
      }

      const updatedAdmins = admins.filter(admin => admin._id !== adminId);
      setAdmins(updatedAdmins);

      setTimeout(() => {
        setSuccess("Admin has been deleted successfully !");
        setTimeout(() => {
          setSuccess(null);
          fetchAdmins()
        }, 2000)
      }, 1000)
    } catch (error) {
      setLoading(false);
      console.error('Error deleting admin:', error);
      setError(`Error deleting cars: ${error.message}. Please try again.`);
    }
  };

  // Example of preparing permissions in a React component
  const handleChangePermission = async (id, permissions) => {
    setLoading(true);
    try {
      // Send the updated permissions to the backend API
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}admins/${id}/permissions`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ permissions }),
        credentials: "include",
      });

      if (!response.ok) {
        setError(`Failed to update admin permissions: ${response.status} ${response.statusText}`)
        throw new Error(`Failed to update admin permissions: ${response.status} ${response.statusText}`);
      }
      
      setLoading(false);
      setSuccess(`Admin ${id} permissions updated successfully.`);

    } catch (error) {
      setLoading(false);
      setError(`Error updating admin permissions: ${error.message}`);
      console.error("Error updating admin permissions:", error.message);
    }
  };



  return (
    <section className='list-of-cars__section'>
      <div className="container mx-auto">
        <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
        <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
        <div className='flex justify-end items-center mb-10'>
          <button className="py-2.5 px-16 bg-primary rounded-md text-white font-bold cursor-pointer">
            <a href='add-admin'>
              Add admin
            </a>
          </button>
        </div>

        {
          !loading &&
          <Table
            columns={columns}
            dataSource={admins}
            bordered
          />
        }

      </div>
    </section>
  )
}

export default ListOfAdmins