import { Alert } from 'antd';
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const userId = location.pathname.split('/').pop();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({});

    useEffect(() => {

        const fetchOffers = async () => {
            try {
                const response = await fetch(`http://localhost:8000/api/v1/admins/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch user: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();

                setFormData({
                    username: data.username,
                    email: data.email,
                });
            } catch (error) {
                console.error('Error fetching user:', error.message);
            }
        };

        fetchOffers()
    }, [userId])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`http://localhost:8000/api/v1/users/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`Failed to update user: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            if (data) {
                setTimeout(() => {
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                        setTimeout(() => {
                            navigate("/list-of-admins")
                        }, 2500)
                    }, 2000)
                }, 1000)
            } else {
                throw new Error('User is not updated: Invalid response from server');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    if (loading) return "Loading...";

    return (
        <section>
            <div className="container mx-auto">
                <Alert
                    message="Admin has been updated successfully!"
                    type="success"
                    showIcon
                    className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`}
                />

                <div className="h-screen flex items-center justify-center">
                    <div className="bg-foreground shadow-md rounded-xl md:rounded-3xl p-[10vw] sm:p-16 md:p-20">
                        <form onSubmit={handleSubmit} className="w-[45vw] sm:w-72 md:w-80">
                            <h1 className="text-copy font-bold text-[5vw] sm:text-3xl md:text-4xl leading-none  sm:leading-tight md:leading-normal tracking-wide drop-shadow-md">Profile</h1>

                            <div className="py-[5vw] sm:py-10 space-y-[3vw] sm:space-y-6">

                                <div className="relative">
                                    <input autoComplete="off" id="username" name="username" value={formData.username || ""} onChange={handleInputChange} disabled={loading} type="text" className="peer placeholder-transparent h-[8vw] sm:h-12 md:h-14 w-full border-b-2 border-border text-copy font-semibold text-[3.5vw] sm:text-xl disabled:font-normal disabled:text-copy-light disabled:bg-transparent focus:outline-none focus:border-primary" placeholder="Please enter username..." />
                                    <label htmlFor="username" className="absolute left-0 -top-[2.5vw] sm:-top-3.5 text-copy-dark text-[2.5vw] sm:text-sm peer-placeholder-shown:text-[2.5vw] sm:peer-placeholder-shown:text-base peer-placeholder-shown:text-copy peer-placeholder-shown:top-[2vw] sm:peer-placeholder-shown:top-4 transition-all peer-focus:-top-[2.5vw] sm:peer-focus:-top-3.5 peer-focus:text-primary peer-focus:text-[2.5vw] sm:peer-focus:text-base">Username</label>
                                </div>

                                <div className="relative">
                                    <input autoComplete="off" id="email" name="email" value={formData.email || ""} onChange={handleInputChange} disabled={loading} type="text" className="peer placeholder-transparent h-[8vw] sm:h-12 md:h-14 w-full border-b-2 border-border text-copy font-semibold text-[3.5vw] sm:text-xl disabled:font-normal disabled:text-copy-light disabled:bg-transparent focus:outline-none focus:border-primary" placeholder="Please enter email..." />
                                    <label htmlFor="email" className="absolute left-0 -top-[2.5vw] sm:-top-3.5 text-copy-dark text-[2.5vw] sm:text-sm peer-placeholder-shown:text-[2.5vw] sm:peer-placeholder-shown:text-base peer-placeholder-shown:text-copy peer-placeholder-shown:top-[2vw] sm:peer-placeholder-shown:top-4 transition-all peer-focus:-top-[2.5vw] sm:peer-focus:-top-3.5 peer-focus:text-primary peer-focus:text-[2.5vw] sm:peer-focus:text-base">Email</label>
                                </div>

                                <div className="relative">
                                    <input autoComplete="off" id="password" name="password" value={formData.password || ""} onChange={handleInputChange} disabled={loading} type="text" className="peer placeholder-transparent h-[8vw] sm:h-12 md:h-14 w-full border-b-2 border-border text-copy font-semibold text-[3.5vw] sm:text-xl disabled:font-normal disabled:text-copy-light disabled:bg-transparent focus:outline-none focus:border-primary" placeholder="Please enter your password.." />
                                    <label htmlFor="password" className="absolute left-0 -top-[2.5vw] sm:-top-3.5 text-copy-dark text-[2.5vw] sm:text-sm peer-placeholder-shown:text-[2.5vw] sm:peer-placeholder-shown:text-base peer-placeholder-shown:text-copy peer-placeholder-shown:top-[2vw] sm:peer-placeholder-shown:top-4 transition-all peer-focus:-top-[2.5vw] sm:peer-focus:-top-3.5 peer-focus:text-primary peer-focus:text-[2.5vw] sm:peer-focus:text-base">Enter new password</label>
                                </div>

                                <div className="flex-center">
                                    <button type="submit" disabled={loading} className="w-fit py-[2vw] sm:py-3.5 px-[5vw] sm:px-8 rounded-[1.5vw] sm:rounded-xl bg-primary hover:bg-background text-white hover:text-primary text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-border hover:border-primary transition-all duration-[0.3s] ease-in-out">Submit</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Profile