import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Input, Select } from 'antd';

const EditAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const adminId = location.pathname.split('/').pop();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});

  const fetchAdminDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}admins/${adminId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        setError(`Failed to fetch admin: ${response.status} ${response.statusText}`)
        throw new Error(`Failed to fetch admin: ${response.status} ${response.statusText}`);
      }

      const { data } = await response.json();
      setFormData(data);

    } catch (error) {
      setError(`Error fetching admin: ${error.message}`)
      console.error('Error fetching admin:', error.message);
    }
  };

  useEffect(() => {
    fetchAdminDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}admins/${adminId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (!response.ok) {
        setError(`Failed to update admin: ${response.status} ${response.statusText}`);
        throw new Error(`Failed to update admin: ${response.status} ${response.statusText}`);
      }

      setTimeout(() => {
        setSuccess("Amin has been updated successfully !");
        setTimeout(() => {
          setSuccess(null);
          setTimeout(() => {
            navigate('/list-of-admins')
          }, 2500)
        }, 2000)
      }, 1000)
    } catch (error) {
      setLoading(false);
      console.error("Error updating user", error.message);
      setError(`Error updating user: ${error.message}. Please try again.`);
    }
  };

  const handleCancel = () => {
    navigate('/list-of-admins');
  };

  return (
    <section className='admin-edit__section'>
      <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
      <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />

      <div className="container mx-auto">
        <div className="flex items-center justify-center">
          <div className="bg-foreground shadow-md rounded-xl md:rounded-3xl p-[10vw] sm:p-16 md:p-20">
            <h1 className="text-copy font-bold text-[5vw] sm:text-3xl md:text-4xl leading-none sm:leading-tight md:leading-normal tracking-wide drop-shadow-md">
              Profile
            </h1>

            <div>
              <div className='mt-2.5 flex items-center gap-3'>
                <span className='w-48 text-copy-dark text-sm leading-tight'>Username:</span>
                <Input
                  value={formData.username || ''}
                  onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                  placeholder="Username"
                  className='disabled:bg-foreground text-copy-dark'
                />
              </div>
              <div className='mt-2.5 flex items-center gap-3'>
                <span className='w-48 text-copy-dark text-sm leading-tight'>Email:</span>
                <Input
                  value={formData.email || ''}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  placeholder="Email"
                  className='disabled:bg-foreground text-copy-dark'
                />
              </div>
              <div className='mt-2.5 flex items-center gap-3'>
                <span className='w-48 text-copy-dark text-sm leading-tight'>Phone number:</span>
                <Input
                  value={formData.phoneNumber || ''}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  placeholder="Phone number"
                  className='disabled:bg-foreground text-copy-dark'
                />
              </div>
              <div className='mt-2.5 flex items-center gap-3'>
                <span className='w-48 text-copy-dark text-sm leading-tight'>Password:</span>
                <Input.Password
                  value={formData.password || ''}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  placeholder="Password"
                  className='disabled:bg-foreground text-copy-dark'
                />
              </div>
              <div className='mt-2.5 flex items-center gap-3'>
                <span className='w-48 text-copy-dark text-sm leading-tight'>Role:</span>
                <Select
                  placeholder="Select a role"
                  onChange={(value) => setFormData({ ...formData, role: value })}
                  value={formData.role || ''}
                  className='w-full block'
                >
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="user">User</Select.Option>
                </Select>
              </div>
              <div className="flex justify-center gap-3 mt-10">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-fit py-[2vw] sm:py-2.5 px-[5vw] sm:px-7 rounded-[1.5vw] sm:rounded-xl bg-primary hover:bg-background text-copy-light hover:text-primary text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-border hover:border-primary disabled:animate-pulse transition-all duration-[0.3s] ease-in-out"
                  onClick={handleSubmit}
                >
                  Update
                </button>
                <button
                  type="button"
                  disabled={loading}
                  onClick={handleCancel}
                  className="w-fit py-[2vw] sm:py-2.5 px-[5vw] sm:px-7 rounded-[1.5vw] sm:rounded-xl bg-error hover:bg-background text-copy-light hover:text-error text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-error disabled:animate-pulse transition-all duration-[0.3s] ease-in-out"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditAdmin;