import Sidebar from '../ui/Sidebar'
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <section className='flex'>
            <Sidebar />
            <div className="flex-1 py-7 pr-7">
                <Outlet />
            </div>
        </section>
    )
}

export default Layout