"use client"

import { useEffect, useState } from "react";

export default function Uploader({ setFormData, removeSelectedImage, carImg }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    useEffect(() => {
        if (removeSelectedImage) {
            setSelectedFileUrl(null)
        }
    }, [removeSelectedImage]);

    const handleInputFiles = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setSelectedFile(file);
            setSelectedFileUrl(fileUrl);
        }
    };

    async function handleUpload() {
        if (!selectedFile) return alert("No image files are selected.");

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('upload_preset', "sam-car-rent");
        formData.append('api_key', process.env.CLODINARY_API_KEY);

        setFormData(formData);
    }

    return (
        <>
            <form encType="multipart/form-data" className="w-full h-full">
                <label htmlFor="file" className="cursor-pointer">
                    <div className="relative w-full h-full flex items-center justify-center border rounded-xl overflow-hidden">
                        {
                            selectedFileUrl || carImg
                                ? <img src={selectedFileUrl || carImg?.url || "/upload.png"} alt="uploader" className="abosolute top-0 left-0 w-full h-full object-contain" />
                                : <span className="text-copy-light text-base font-bold leading-normal">Select picture</span>
                        }
                    </div>
                    <input className='opacity-0' name='file' type='file' id='file' onChange={handleInputFiles} />
                </label>
            </form>
        </>
    );
}
