import { Alert, Button, Form, Input, Select } from 'antd';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddAdmin = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();

  const formItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 100,
      },
    },
  };

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}admins/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
        credentials: 'include',
      });

      if (!res.ok) {
        if (res.status === 409) {
          setError("User with these username and email already exists !")
          throw new Error("Username or Email already exists");
        } else {
          setError(`Failed to create admin: ${res.status} ${res.statusText}`)
          throw new Error(`Failed to create admin: ${res.status} ${res.statusText}`);
        }
      }


      setTimeout(() => {
        setSuccess("User has been created successfully !");
        setTimeout(() => {
          setSuccess(null);
          setLoading(false);
          setTimeout(() => {
            navigate('/list-of-admins');
          }, 2500)
        }, 2000)
      }, 1000)

    } catch (error) {
      setLoading(false);
      console.error("Error creating user", error.message);
      setError(`Error creating user: ${error.message}. Please try again.`);
    }
  };



  return (
    <section>
      <div className="container mx-auto">
        <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
        <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />

        <div className="h-screen flex items-center justify-center">
          <div className="w-[480px] bg-foreground shadow-md rounded-xl md:rounded-3xl p-[10vw] sm:p-16 md:p-20">
            <h1 className="mb-5 text-copy font-bold text-[5vw] sm:text-3xl md:text-4xl leading-none  sm:leading-tight md:leading-normal tracking-wide drop-shadow-md">Add admin</h1>

            <Form
              onFinish={onFinish}
              layout="vertical"
              variant="filled"
              {...formItemLayout}
              form={form}
            >
              <Form.Item style={{ flex: "1" }} hasFeedback name="username" validateDebounce={1000} label="Username">
                <Input placeholder='Username' />
              </Form.Item>
              <Form.Item style={{ flex: "1" }} hasFeedback name="email" validateDebounce={1000} label="Email">
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item style={{ flex: "1" }} hasFeedback name="phoneNumber" validateDebounce={1000} label="Phone">
                <Input placeholder='Phone number' />
              </Form.Item>
              <Form.Item hasFeedback name="role" validateDebounce={1000} label="Select">
                <Select placeholder="Select a role">
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="user">User</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item hasFeedback name="password" validateDebounce={1000} label="Password">
                <Input.Password placeholder="****" />
              </Form.Item>
              <div className='flex justify-center mt-5'>
                <Button disabled={loading} type="primary" htmlType="submit">
                  Add admin
                </Button>
              </div>
            </Form>

          </div>
        </div>
      </div>
    </section >
  )
}

export default AddAdmin