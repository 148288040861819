import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import Uploader from '../components/ui/Uploader';

const AddRental = () => {
    const navigate = useNavigate();

    const finished = false;
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [removeSelectedImage, setRemoveSelectedImage] = useState(false);
    const [formImg, setFormImg] = useState(null);

    const [form] = Form.useForm();

    const [limited, setLimited] = useState(false);
    const [returnLocationFees, setReturnLocationFees] = useState(false);
    const [kidsSeat, setKidsSeat] = useState(false);
    const [deliveryFees, setDeliveryFees] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const cloundName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
            const result = await fetch(`https://api.cloudinary.com/v1_1/${cloundName}/image/upload`, {
                method: 'POST',
                body: formImg
            }).then(res => res.json());

            if (!result.secure_url) {
                throw new Error('Image upload failed');
            }

            const data = { ...values, imgCar: { url: result?.secure_url, public_id: result?.public_id } }

            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}cars/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include',
            });

            if (!res.ok) {
                setError(`Failed to create application: ${res.status} ${res.statusText}`)
                throw new Error(`Failed to create application: ${res.status} ${res.statusText}`);
            }

            setFormImg(null);
            setRemoveSelectedImage(null)

            setTimeout(() => {
                setSuccess("Application has been created successfully !");
                setTimeout(() => {
                    setSuccess(null);
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/list-of-rental')
                    }, 2500)
                }, 2000)
            }, 1000)
        } catch (error) {
            setLoading(false);
            console.error("Error creating user", error.message);
            setError(`Error creating user: ${error.message}. Please try again.`);
        }

    };

    if (!finished) {
        return (
            <div class="h-screen flex flex-col justify-center items-center">
                <img src="/samcarrent.png" alt="Logo" class="object-cover w-40 mb-8" />
                <h1 class="text-primary text-4xl font-bold mb-4">Coming Soon</h1>
                <p class="text-lg mb-8 px-4 md:px-0">We're working hard to bring you something awesome. Stay tuned!</p>
                <div class="flex justify-center items-center space-x-4">
                    <a href="/">
                        <button className='py-3.5 px-16 bg-primary rounded-xl text-copy-light font-semibold leading-tight'>Go Back</button>
                    </a>
                </div>
            </div>
        )
    }

    return (
        <section className='add-rental__section'>
            <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            
            <div className="container mx-auto">
                <Form
                    onFinish={onFinish}
                    {...formItemLayout}
                    variant="filled"
                    form={form}
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Rental details" bordered={false}>
                                <Form.Item hasFeedback name="make" validateDebounce={1000} label="Make">
                                    <Input placeholder="Chevrolet" />
                                </Form.Item>
                                <Form.Item hasFeedback name="model" validateDebounce={1000} label="Model">
                                    <Input placeholder="Gentra" />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Rental informations" bordered={false}>
                                <Form.Item hasFeedback name="make" validateDebounce={1000} label="Make">
                                    <Input placeholder="Chevrolet" />
                                </Form.Item>
                                <Form.Item hasFeedback name="model" validateDebounce={1000} label="Model">
                                    <Input placeholder="Gentra" />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Rental checkout" bordered={false}>
                                <Form.Item hasFeedback name="make" validateDebounce={1000} label="Make">
                                    <Input placeholder="Chevrolet" />
                                </Form.Item>
                                <Form.Item hasFeedback name="model" validateDebounce={1000} label="Model">
                                    <Input placeholder="Gentra" />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <div className='flex justify-center mt-5'>
                        <Button disabled={loading} type="primary" htmlType="submit">
                            Add Car
                        </Button>
                    </div>
                </Form>
            </div>
        </section>
    )
}

export default AddRental