import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';

const Authentication = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(null);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}admin/auth/sing-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            if (!res.ok) {
                setError(`Failed to authenticate: ${res.status} ${res.statusText}`)
                throw new Error(`Failed to authenticate: ${res.status} ${res.statusText}`);
            }

            const data = await res.json();

            if (data && data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.admin));
                setSuccess("Authenticated")

                setTimeout(() => {
                    setSuccess("Autheticated successfully !");
                    setTimeout(() => {
                        setSuccess(null);
                        setLoading(false);
                        setTimeout(() => {
                            navigate('/');
                        }, 2500)
                    }, 2000)
                }, 1000)
            } else {
                setError("Authentication failed: Invalid response from server")
                throw new Error("Authentication failed: Invalid response from server");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error authenticated", error.message);
            setError(`Error authenticated: ${error.message}. Please try again.`);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            navigate("/")
        }
    }, [navigate])

    return (
        <section className='authentification__section'>
            <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />

            <div className="container mx-auto">
                <div className="h-screen flex items-center justify-center">

                    <div className="relative bg-primary rounded-xl md:rounded-3xl shadow-xl p-[10vw] sm:p-16 md:p-20">
                        <form onSubmit={handleSubmit} className="w-[45vw] sm:w-72 md:w-96">
                            <h1 className="text-copy-light font-bold text-[5vw] sm:text-3xl md:text-4xl leading-none  sm:leading-tight md:leading-normal tracking-wide drop-shadow-md">Staff Page</h1>

                            <div className="py-[5vw] sm:py-10 space-y-[3vw] sm:space-y-10">
                                <div className="relative">
                                    <label htmlFor="username" className="px-0.5 mb-0.5 text-copy-light/90 text-base font-normal leading-norma tracking-normal">Username</label>
                                    <input autoComplete="off" id="username" name="username" value={formData.username} onChange={handleInputChange} disabled={loading} type="text" className="h-[8vw] sm:h-12 md:h-14 w-full px-3 text-copy-dark font-semibold text-[3.5vw] sm:text-sm md:text-lg border-b-2 border-border rounded-xl focus:outline-none disabled:animate-pulse" placeholder="Your username..." />
                                </div>
                                <div className="relative">
                                    <label htmlFor="password" className="px-0.5 mb-0.5 text-copy-light/90 text-base font-normal leading-norma tracking-normal">Password</label>
                                    <input autoComplete="off" id="password" name="password" value={formData.password} onChange={handleInputChange} disabled={loading} type="text" className="h-[8vw] sm:h-12 md:h-14 w-full px-3 text-copy-dark font-semibold text-[3.5vw] sm:text-sm md:text-lg border-b-2 border-border rounded-xl focus:outline-none disabled:animate-pulse" placeholder="Your password.." />
                                </div>
                                <div className="flex-center">
                                    <button type="submit" disabled={loading} className="w-fit py-[2vw] sm:py-3.5 px-[5vw] sm:px-8 rounded-[1.5vw] sm:rounded-xl bg-primary hover:bg-background text-white hover:text-primary text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-border hover:border-primary transition-all duration-[0.3s] ease-in-out">Submit</button>
                                </div>
                            </div>

                            <div className="flex-center text-center mb-2.5">
                                {
                                    !error && <span className="text-copy-light font-normal text-[2vw] sm:text-[10px] md:text-xs drop-shadow-md leading-snug">This web page for only admins <a href="/terms" className="text-success">read more</a></span>
                                }
                                {
                                    error && <span className="text-error font-bold text-[2vw] sm:text-[10px] md:text-xs drop-shadow-md leading-snug">{error}</span>
                                }
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section >
    )
}

export default Authentication