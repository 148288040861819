import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import Loader from '../components/ui/Loader';

const Payment = () => {
    const location = useLocation();
    const rentalId = location.pathname.split('/').pop();

    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(null)

    const fetchPeyment = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}payments/${rentalId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch rent cars: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();

            setLoading(false);
            setPayment(data);


        } catch (error) {
            console.error('Error fetching rent cars:', error.message);
        }
    }

    useEffect(() => {
        fetchPeyment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (loading) <Loader />

    return (
        <section className='payment__section'>
            <div className="container mx-auto">

                <div className='h-screen flex items-center justify-center'>
                    <div class="bg-background shadow-md">
                        <div class="p-7">
                            <div className='h-[520px] w-[480px] mb-5 border-2 rounded-md overflow-hidden'>
                                <img src={payment?.checkForPayment?.url || "/money.png"} alt={payment?.id} className='w-full h-full object-cover' />
                            </div>
                            <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
                                <path fill="currentColor"
                                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                                </path>
                            </svg>
                            <div class="text-center">
                                <h3 class="md:text-2xl text-base text-copy-dark font-semibold text-center">Payment Done!</h3>
                                <p class="text-copy-dark my-2">{new Date(payment?.createdAt).toDateString()}</p>
                                <p className='md:text-2xl text-base text-copy-dark font-semibold text-center'>{payment?.paymentAmount}$</p>
                                <div class="py-10 text-center">
                                    <a href="/list-of-rental">
                                        <button className='py-3.5 px-16 bg-primary rounded-xl text-copy-light font-semibold leading-tight'>Go Back</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default Payment