import { useEffect, useState } from 'react';
import moment from 'moment';

const Chart = ({ bookings }) => {
    const [activeCount, setActiveCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [canceledCount, setCanceledCount] = useState(0);
    const [upcomingEarnings, setUpcomingEarnings] = useState(0);
    const [completedEarnings, setCompletedEarnings] = useState(0);
    const [canceledEarnings, setCanceledEarnings] = useState(0);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const now = moment();
        const startOfMonth = now.startOf('month').toDate();

        const thisMonth = bookings.filter(booking => {
            const createdAt = new Date(booking.createdAt);
            return createdAt >= startOfMonth;
        });

        const active = thisMonth.filter(booking => booking.rentcarStatus === 'pending');
        const completed = thisMonth.filter(booking => booking.rentcarStatus === 'completed');
        const canceled = thisMonth.filter(booking => booking.rentcarStatus === 'canceled' || booking.rentcarStatus === 'rejected');

        setActiveCount(active.length);
        setCompletedCount(completed.length);
        setCanceledCount(canceled.length);

        setUpcomingEarnings(active.reduce((acc, booking) => acc + booking.totalCost, 0));
        setCompletedEarnings(completed.reduce((acc, booking) => acc + booking.totalCost, 0));
        setCanceledEarnings(canceled.reduce((acc, booking) => acc + booking.totalCost, 0));

        // Generate transaction data from paymentMethod
        const paymentTransactions = thisMonth.map(booking => ({
            transaction: booking._id,
            dateTime: new Date(booking.createdAt).toLocaleString(),
            amount: booking.paymentMethod ? `$${booking.paymentMethod.paymentAmount}` : 'N/A',
            status: booking.paymentMethod ? booking.paymentMethod.paymentStatus : 'N/A'
        }));

        setTransactions(paymentTransactions);
    }, [bookings]);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-2xl">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    <div className="rounded-2xl border border-dashed border-gray-200 p-4">
                        <div className="flex items-center mb-0.5">
                            <div className="text-xl font-semibold">{activeCount}</div>
                            <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">${upcomingEarnings}</span>
                        </div>
                        <span className="text-gray-400 text-sm">Active</span>
                    </div>
                    <div className="rounded-2xl border border-dashed border-gray-200 p-4">
                        <div className="flex items-center mb-0.5">
                            <div className="text-xl font-semibold">{completedCount}</div>
                            <span className="p-1 rounded text-[12px] font-semibold bg-emerald-500/10 text-emerald-500 leading-none ml-1">+${completedEarnings}</span>
                        </div>
                        <span className="text-gray-400 text-sm">Completed</span>
                    </div>
                    <div className="rounded-2xl border border-dashed border-gray-200 p-4">
                        <div className="flex items-center mb-0.5">
                            <div className="text-xl font-semibold">{canceledCount}</div>
                            <span className="p-1 rounded text-[12px] font-semibold bg-rose-500/10 text-rose-500 leading-none ml-1">-${canceledEarnings}</span>
                        </div>
                        <span className="text-gray-400 text-sm">Canceled</span>
                    </div>
                </div>

                <div>
                    <canvas id="order-chart"></canvas>
                </div>
            </div>

            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-2xl">
                <h4 className="text-copy-dark text-xl font-bold leading-normal mb-3">Payments</h4>

                <div className="overflow-x-auto">
                    <table className="w-full min-w-[520px]">
                        <thead>
                            <tr>
                                <th className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left rounded-tl-md rounded-bl-md">TRANSACTION</th>
                                <th className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left">DATE & TIME</th>
                                <th className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left rounded-tr-md rounded-br-md">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b border-b-gray-50">
                                        <a href={`/payment/${transaction.transaction}`} className="text-gray-600 text-sm font-medium hover:text-blue-500 ml-2 truncate">#{transaction.transaction}</a>
                                    </td>
                                    <td className="py-2 px-4 border-b border-b-gray-50">
                                        <span className="text-[13px] font-medium text-emerald-500">{new Date(transaction.dateTime).toDateString()}</span>
                                    </td>
                                    <td className="py-2 px-4 border-b border-b-gray-50">
                                        <span className={`inline-block p-1 rounded ${transaction.status === 'Pending' ? 'bg-emerald-500/10 text-emerald-500' : 'bg-red-500/10 text-red-500'} font-medium text-[12px] leading-none`}>
                                            {transaction.amount} ({transaction.status})
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Chart;