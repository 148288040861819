import { useEffect, useState } from "react";
import { Admin, Car, Comment, Dashboard, LogOut, Rental } from "../../icons/icons"

const Sidebar = () => {
    const [user, setUser] = useState("")

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (user) {
            setUser(user)
        }
    }, [])

    const handleLogOut = () => {
        localStorage.removeItem("token");
        window.location.reload();
    }

    return (
        <aside className="p-7 w-80 sticky top-0 z-10">
            <div className="hidden md:block py-2.5 px-3.5 bg-foreground rounded-2xl overflow-hidden">

                {/* Logo of Company */}
                <div className="flex justify-center items-center my-3">
                    <img src="/samcarrent.png" alt="" className="w-full h-full object-cover object-center" />
                </div>

                {/* Sidebar Navigation */}
                <ul className='flex flex-col justify-start items-start gap-y-2.5 mt-3'>
                    <a href="/" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-md p-1.5">
                        <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                            <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                                <Dashboard className="w-5 h-5 stroke-white fill-primary" />
                            </div>
                            <span className={`text-copy text-lg font-bold tracking-tight leading-normal`}>Dashboard</span>
                        </li>
                    </a>
                    <a href="/list-of-rental" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-md p-1.5">
                        <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                            <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                                <Rental className="w-5 h-5 stroke-white fill-primary" />
                            </div>
                            <span className={`text-copy text-lg font-bold tracking-tight leading-normal`}>List of Rental</span>
                        </li>
                    </a>
                    <a href="/list-of-cars" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-md p-1.5">
                        <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                            <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                                <Car className="w-5 h-5 stroke-white fill-primary" />
                            </div>
                            <span className={`text-copy text-lg font-bold tracking-tight leading-normal`}>List of Cars</span>
                        </li>
                    </a>
                    <a href="/list-of-admins" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-md p-1.5">
                        <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                            <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                                <Admin className="w-5 h-5 stroke-white fill-primary" />
                            </div>
                            <span className={`text-copy text-lg font-bold tracking-tight leading-normal`}>List of Admins</span>
                        </li>
                    </a>
                    <a href="/list-of-comments" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-md p-1.5">
                        <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                            <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                                <Comment className="w-5 h-5 stroke-white fill-primary" />
                            </div>
                            <span className={`text-copy text-lg font-bold tracking-tight leading-normal`}>List of Comments</span>
                        </li>
                    </a>
                </ul>

                {/* Sidebar Footer */}
                <div className="py-5 mt-72 border-t">
                    <div className="flex items-center justify-between gap-4">
                        <div className="w-12 h-12 border rounded-full overflow-hidden">
                            <img src="./user.png" alt="" />
                        </div>
                        <div className="flex-1 space-y-1">
                            {
                                user &&
                                <a href={`/profile/${user?._id}`}>
                                    <h3 className="text-copy-dark text-base font-bold leading-tight">{user?.email.slice(0, 10)}...</h3>
                                    <p className="text-copy-dark text-xs font-normal leading-tight">{user?.role}</p>
                                </a>
                            }
                        </div>
                        <button onClick={handleLogOut} className="border rounded-md hover::shadow-sm">
                            <LogOut className="w-8 h-8 stroke-white fill-primary" />
                        </button>
                    </div>
                </div>

            </div>
        </aside>

    )
}
export default Sidebar;