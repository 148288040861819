import { useEffect, useState } from 'react';


import ListRentCars from '../components/ui/ListRentCars';
import { Alert } from 'antd';


const ListOfRentCar = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [rentCars, setRentCars] = useState([]);

    const fetchRentedCars = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}booking`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (!response.ok) {
                setError(`Failed to fetch rent cars: ${response.status} ${response.statusText}`)
                throw new Error(`Failed to fetch rent cars: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            setLoading(false);
            setRentCars(data);

            if (data) {
                const dataSource = data.map((item, i) => ({
                    key: i,
                    id: item?._id,
                    client: `${item.billingInfo?.firstName} ${item.billingInfo?.lastName}`,
                    phoneNumber: `+${item.billingInfo?.phoneNumber}`,
                    date: `${new Date(item.rentalDetails?.pickUpDate).toDateString()}`,
                    rentalType: `${(item?.rentalType).toUpperCase()}`,
                    paymentMethod: `${(item?.paymentMethod?.typePayment)}`,
                    status: item.rentcarStatus,
                    pickUpDate: `${new Date(item.rentalDetails?.pickUpDate).toDateString()}`,
                    dropOffDate: `${new Date(item.rentalDetails?.dropOffDate).toDateString()}`,
                    pickUpLocation: item.rentalDetails?.pickUpLocation,
                    dropOffLocation: item.rentalDetails?.dropOffLocation ? item.rentalDetails?.dropOffLocation : item.rentalDetails?.pickUpLocation,
                    car: item?.car,
                    rentalDurationType: item?.rentalDurationType,
                    totalCost: item?.totalCost,
                }));

                setRentCars(dataSource);
            }

        } catch (error) {
            setLoading(false)
            console.error('Error fetching rent cars:', error.message);
            setError(`Error fetching rent cars: ${error.message}. Please try again.`);
        }
    };

    useEffect(() => {
        fetchRentedCars();
    }, []);

    const handleChangeStatus = async ({ id, key }) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}booking/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ rentcarStatus: key }),
                credentials: 'include',
            });

            if (!res.ok) {
                setError(`Failed to edit car's status: ${res.status} ${res.statusText}`);
                throw new Error(`Failed to edit car's status: ${res.status} ${res.statusText}`);
            }

            const updatedStatus = rentCars.filter(status => status._id !== id);
            setRentCars(updatedStatus);

            setTimeout(() => {
                setSuccess("Rental aplication's status has been updated successfully !");
                setTimeout(() => {
                    setSuccess(null);
                }, 2000)
            }, 1000)

        } catch (error) {
            console.error('Error updating ren car\'s status', error.message);
            setError("Something went wrong during admin deletion. Please try again.");
        }
    };



    return (
        <section className='list-of-rent-cars__section'>
            <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            
            <div className='flex justify-end items-center mb-10'>
                <a href="/add-rental">
                    <button className='py-3.5 px-16 bg-primary rounded-xl text-copy-light font-semibold leading-tight'>New Rental</button>
                </a>
            </div>

            <div className='mt-5 text-2xl text-copy-dark font-semibold leading-normal'>
                {
                    !loading &&
                    <ListRentCars data={rentCars} handleChangeStatus={handleChangeStatus} />
                }
            </div>

        </section>
    );
};

export default ListOfRentCar;