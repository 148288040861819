import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utils/helper';

const PrivateRouteWrapper = ({ children }) => {
    const navigate = useNavigate();
    const isLogged = isLoggedIn();

    useEffect(() => {
        if (!isLogged) {
            navigate('/login', { replace: true });
        }
    }, [isLogged, navigate]);

    return isLogged ? <>{children}</> : null;
};

export default PrivateRouteWrapper;
