import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import { Provider } from "react-redux";
import store  from "./redux/store";

import { BrowserRouter as Router } from "react-router-dom";
import RooteRoute from "./routes/RooteRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <RooteRoute />
      </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
