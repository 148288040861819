import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Select, Space, Table } from 'antd';
import { useRef, useState } from 'react';

import Highlighter from 'react-highlight-words';
import { items } from '../../data';
import { Typography } from 'antd';
const { Paragraph } = Typography;


const ListRentCars = ({ data, handleChangeStatus }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);




    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const rentalCarOptions = [
        {
            title: "ID",
            dataIndex: "",
            render: (item) => (
                <>
                    {`#${item.id.slice(0, 8)}...`}
                </>
            ),
        },
        {
            title: "Client",
            dataIndex: "client",
            filterSearch: true,
            ...getColumnSearchProps('client'),
        },
        {
            title: "Phone number",
            dataIndex: "phoneNumber",
        },
        {
            title: "Pick-up date",
            dataIndex: "date",
        },
        {
            title: "Rental Type",
            dataIndex: "rentalType",
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
        },
        {
            title: "Status",
            dataIndex: "",
            width: 200,
            render: (record) => (
                <Select
                    labelInValue
                    defaultValue={{ label: record.status }}
                    onChange={(selectedValue) => handleChangeStatus({ key: selectedValue?.value, id: record?.id })}
                    options={items}
                    style={{ width: "100%", textTransform: "capitalize"}}
                >
                </Select>
            ),
        },
        {
            title: "Action",
            dataIndex: "",
            fixed: 'right',
            render: (item) => (
                <a
                    href={`/list-of-rental/${item.id}`}
                    className="py-2.5 px-10 hover:bg-primary border border-primary rounded-lg text-primary hover:text-copy-light text-lg font-semibold leading-tight transition-all duration-300"
                >
                    View
                </a>
            ),
        },
    ];

    return (
        <Table
            columns={rentalCarOptions}
            expandable={{
                expandedRowRender: (record) => (
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Pick-up" bordered={false}>
                                <Paragraph copyable>{record?.pickUpDate}</Paragraph>
                                <Paragraph copyable>{record?.pickUpLocation}</Paragraph>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Drop-off" bordered={false}>
                                <Paragraph copyable>{record?.dropOffDate}</Paragraph>
                                <Paragraph copyable>{record?.dropOffLocation}</Paragraph>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Car details" bordered={false}>
                                <Paragraph copyable>Details: {record.car?.make} {record.car?.model} - {record.car?.color}</Paragraph>
                                <Paragraph copyable>Total cost: {record?.totalCost}</Paragraph>
                            </Card>
                        </Col>
                    </Row>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            dataSource={data}
        />
    )
}

export default ListRentCars