import { Alert, Button, Form, Input } from 'antd';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddComment = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();

    const formItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 100,
            },
        },
    };

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}comments/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
                credentials: 'include',
            });

            if (!res.ok) {
                setError(`Failed to create cooment: ${res.status} ${res.statusText}`)
                throw new Error(`Failed to create cooment: ${res.status} ${res.statusText}`);
            }

            setTimeout(() => {
                setSuccess("Cooment has been created successfully !");
                setTimeout(() => {
                    setSuccess(null);
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/list-of-comments');
                    }, 2500)
                }, 2000)
            }, 1000)

        } catch (error) {
            setLoading(false);
            console.error("Error creating comment", error.message);
            setError(`Error creating comment: ${error.message}. Please try again.`);
        }
    };
    return (
        <section className='add-comment__section'>
            <div className="container mx-auto">
                <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
                <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
                
                <div className="h-screen flex items-center justify-center">
                    <div className="w-[480px] bg-foreground shadow-md rounded-xl md:rounded-3xl p-[10vw] sm:p-16 md:p-20">
                        <h1 className="mb-5 text-copy font-bold text-[5vw] sm:text-3xl md:text-4xl leading-none  sm:leading-tight md:leading-normal tracking-wide drop-shadow-md">Add admin</h1>

                        <Form
                            onFinish={onFinish}
                            layout="vertical"
                            variant="filled"
                            {...formItemLayout}
                            form={form}
                        >
                            <Form.Item style={{ flex: "1" }} hasFeedback name="firstName" validateDebounce={1000} label="First name">
                                <Input placeholder='First name' />
                            </Form.Item>
                            <Form.Item style={{ flex: "1" }} hasFeedback name="lastName" validateDebounce={1000} label="Last name">
                                <Input placeholder='Last name' />
                            </Form.Item>
                            <Form.Item style={{ flex: "1" }} hasFeedback name="stars" validateDebounce={1000} label="Stars">
                                <Input placeholder='Stars' />
                            </Form.Item>
                            <Form.Item style={{ flex: "1" }} hasFeedback name="comment" validateDebounce={1000} label="Comment">
                                <Input.TextArea placeholder='Comment' />
                            </Form.Item>
                            <div className='flex justify-center mt-5'>
                                <Button disabled={loading} type="primary" htmlType="submit">
                                    Add comment
                                </Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
        </section >
    )
}

export default AddComment