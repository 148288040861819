import { useEffect, useState } from 'react'
import { Alert, Table } from "antd";
import { Trush } from "../icons/icons";


const ListOfComments = () => {
    const [comments, setComments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);


    const columns = [
        {
            title: 'First name',
            dataIndex: 'firstName',
            width: 150,

        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            width: 150,
        },
        {
            title: 'Stars',
            dataIndex: 'stars',
            width: 150,
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            width: 150,
        },
        {
            title: "Comments",
            dataIndex: "comment",
            width: 250,
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 150,
            render: (item) => (
                <div className="flex justify-center items-center gap-5">
                    <button onClick={() => handleDeleteComment(item._id)} className="p-2.5 rounded-xl border border-border">
                        <Trush className="w-5 h-5 fill-red-600" />
                    </button>
                </div>
            ),
        },
    ];


    const fetchComments = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}comments`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                setError(`Failed to fetch comments: ${response.status} ${response.statusText}`)
                throw new Error(`Failed to fetch comments: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            setLoading(false);
            setComments(data);

        } catch (error) {
            setLoading(false);
            console.error('Error fetching comments:', error.message);
            setError(`Error fetching comments: ${error.message}. Please try again.`);
        }
    };

    useEffect(() => {
        fetchComments()
    }, [])

    const handleDeleteComment = async (adminId) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}comments/${adminId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                setError(`Failed to delete comments: ${response.status} ${response.statusText}`)
                throw new Error(`Failed to delete comments: ${response.status} ${response.statusText}`);
            }

            const updatedComments = comments.filter(admin => admin._id !== adminId);
            setComments(updatedComments);

            setTimeout(() => {
                setSuccess("Comment has been deleted successfully !");
                setTimeout(() => {
                    setSuccess(null);
                    fetchComments();
                }, 2000)
            }, 1000)

        } catch (error) {
            setLoading(false);
            console.error('Error deleting comment:', error.message);
            setError(`Error deleting comment: ${error.message}. Please try again.`);
        }
    };


    return (
        <section className='list-of-cars__section'>
            <div className="container mx-auto">
                <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
                <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />

                <div className='flex justify-end items-center mb-10'>
                    <button className="py-2.5 px-16 bg-primary rounded-md text-white font-bold cursor-pointer">
                        <a href='/add-comment'>
                            Add comment
                        </a>
                    </button>
                </div>
                {
                    !loading &&
                    <Table
                        columns={columns}
                        dataSource={comments}
                        bordered
                    />
                }
            </div>
        </section>
    )
}

export default ListOfComments