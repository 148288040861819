export const User = (props) => {
  return (
    <svg
      className={props.className}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_201_73701)">
        <path
          d="M13.9951 26.0244C20.5342 26.0244 25.9482 20.6103 25.9482 14.0712C25.9482 7.54395 20.5225 2.11816 13.9834 2.11816C7.45605 2.11816 2.04199 7.54395 2.04199 14.0712C2.04199 20.6103 7.46777 26.0244 13.9951 26.0244Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          d="M8.47558 20.2119C7.9834 20.2119 7.76074 19.8955 7.76074 19.4502C7.76074 18.1376 9.75293 14.7041 13.9951 14.7041C18.2373 14.7041 20.2295 18.1376 20.2295 19.4502C20.2295 19.8955 20.0068 20.2119 19.5147 20.2119H8.47558ZM13.9951 13.708C12.2842 13.6962 10.8897 12.2548 10.8897 10.3096C10.8897 8.50488 12.2842 7.00488 13.9951 7.00488C15.7061 7.00488 17.1006 8.50488 17.1006 10.3096C17.1006 12.2548 15.7061 13.7197 13.9951 13.708Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_73701">
          <rect
            width="23.9062"
            height="23.918"
            fill="white"
            transform="translate(2.04199 2.10645)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

// PAGES ICONS
export const Dashboard = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 24 24">
      <path
        d="M21.5 10.9V4.1C21.5 2.6 20.86 2 19.27 2H15.23C13.64 2 13 2.6 13 4.1V10.9C13 12.4 13.64 13 15.23 13H19.27C20.86 13 21.5 12.4 21.5 10.9Z"
        fill="current"
      />
      <path
        d="M11 13.1V19.9C11 21.4 10.36 22 8.77 22H4.73C3.14 22 2.5 21.4 2.5 19.9V13.1C2.5 11.6 3.14 11 4.73 11H8.77C10.36 11 11 11.6 11 13.1Z"
        fill="current"
      />
      <path
        d="M21.5 19.9V17.1C21.5 15.6 20.86 15 19.27 15H15.23C13.64 15 13 15.6 13 17.1V19.9C13 21.4 13.64 22 15.23 22H19.27C20.86 22 21.5 21.4 21.5 19.9Z"
        fill="current"
      />
      <path
        d="M11 6.9V4.1C11 2.6 10.36 2 8.77 2H4.73C3.14 2 2.5 2.6 2.5 4.1V6.9C2.5 8.4 3.14 9 4.73 9H8.77C10.36 9 11 8.4 11 6.9Z"
        fill="current"
      />
    </svg>
  );
};

export const Rental = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 21 28">
      <path d="M16.5275 4.17334H17.8636C18.3792 4.17334 18.6369 4.45459 18.6369 5.05224V22.9116C18.6369 23.6147 18.5315 23.8725 17.8636 24.1655C15.5315 25.185 12.2502 25.8413 9.15649 25.8413C7.35181 25.8413 5.61743 25.7241 3.82446 25.4194V25.5718C8.78149 25.6421 13.5159 24.2475 15.6722 22.5952C16.5042 21.9624 16.7502 21.5405 16.7502 20.5093V1.81787C16.7502 0.657715 16.1644 0.0952148 15.133 0.0952148C14.7815 0.0952148 14.3596 0.177246 13.8558 0.341309C10.3167 1.50146 7.08227 2.23974 2.93384 2.23974H1.99634C0.730712 2.23974 0.00415039 2.97802 0.00415039 4.12646V24.2358C0.00415039 25.1499 0.203369 25.7007 1.07056 26.1225C3.07446 26.9897 6.07446 27.564 9.15649 27.564C12.4377 27.564 16.1292 27.0718 18.7775 25.7827C20.1838 25.1147 20.5237 24.4936 20.5237 23.0405V5.05224C20.5237 3.1538 19.68 2.28662 17.8519 2.28662H16.5275V4.17334ZM1.89087 23.3569V4.63037C1.89087 4.30225 2.06665 4.10302 2.37134 4.10302H3.01587C7.11743 4.10302 10.9495 3.28271 14.219 2.25146C14.6175 2.12255 14.8636 2.22802 14.8636 2.5913V20.0991C14.8636 20.7319 14.7815 20.9897 14.1839 21.3764C11.7464 23.0054 7.79712 24.0132 2.30103 23.7788C2.00806 23.7671 1.89087 23.6382 1.89087 23.3569ZM4.28149 7.19677V11.0405C4.28149 11.228 4.4104 11.31 4.56274 11.31C7.31665 11.31 10.1058 10.8179 12.2151 9.97412C12.3206 9.92725 12.3792 9.82177 12.3792 9.7163V5.70849C12.3792 5.43896 12.133 5.38037 12.0042 5.43896C9.57837 6.31787 7.21118 6.78662 4.56274 6.89209C4.39868 6.9038 4.28149 7.03271 4.28149 7.19677Z" />
    </svg>
  );
};

export const Admin = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 39 33">
      <path d="M8.97754 16.4278C11.3916 16.4278 13.3721 14.2599 13.3721 11.5997C13.3721 8.99817 11.4151 6.90051 8.97754 6.90051C6.56348 6.90051 4.58301 9.03332 4.58301 11.6231C4.58301 14.2716 6.56348 16.4278 8.97754 16.4278ZM8.97754 14.6817C7.54785 14.6817 6.3291 13.3106 6.3291 11.6231C6.3291 9.97082 7.53613 8.64661 8.97754 8.64661C10.4541 8.64661 11.6377 9.94739 11.6377 11.5997C11.6377 13.3106 10.419 14.6817 8.97754 14.6817ZM2.46191 27.1739H15.4815C17.0752 27.1739 17.8604 26.5294 17.8604 25.2169C17.8604 21.3263 13.8877 17.7638 8.96582 17.7638C4.05567 17.7638 0.0712891 21.3263 0.0712891 25.2169C0.0712891 26.5294 0.868164 27.1739 2.46191 27.1739ZM2.16895 25.4395C1.93457 25.4395 1.81738 25.3458 1.81738 25.1114C1.81738 22.2052 5.13379 19.4981 8.96582 19.4981C12.8096 19.4981 16.1143 22.2052 16.1143 25.1114C16.1143 25.3458 16.0088 25.4395 15.7744 25.4395H2.16895Z" />
      <path d="M15.1182 28.7676H29.8369C32.9541 28.7676 34.3135 27.291 34.3135 25.2988C34.3135 21.3144 29.6963 16.1816 22.4775 16.1816C15.2471 16.1816 10.6416 21.3144 10.6416 25.2988C10.6416 27.291 11.9893 28.7676 15.1182 28.7676Z" />
      <path d="M22.4775 16.3224C25.2666 16.3224 27.5401 13.8497 27.5401 10.8263C27.5401 7.8263 25.2783 5.47083 22.4775 5.47083C19.7002 5.47083 17.4151 7.87318 17.4151 10.8497C17.4151 13.8614 19.7002 16.3224 22.4775 16.3224ZM22.4775 14.5528C20.7666 14.5528 19.3018 12.9239 19.3018 10.8497C19.3018 8.81068 20.7432 7.24037 22.4775 7.24037C24.2236 7.24037 25.6533 8.77552 25.6533 10.8263C25.6533 12.9005 24.2119 14.5528 22.4775 14.5528ZM15.1182 27.1739H29.8369C31.7822 27.1739 32.708 26.588 32.708 25.2989C32.708 22.2286 28.8291 17.7872 22.4775 17.7872C16.1143 17.7872 12.2354 22.2286 12.2354 25.2989C12.2354 26.588 13.1611 27.1739 15.1182 27.1739ZM14.5557 25.4044C14.251 25.4044 14.1221 25.3224 14.1221 25.0763C14.1221 23.1427 17.0986 19.5567 22.4775 19.5567C27.8447 19.5567 30.8213 23.1427 30.8213 25.0763C30.8213 25.3224 30.7041 25.4044 30.3994 25.4044H14.5557Z" />
      <path d="M32.919 15.256C29.9658 15.256 27.6104 17.6231 27.6104 20.5411C27.6104 22.3341 28.4775 23.9513 29.8838 24.9239V29.4356C29.8838 30.0567 30.1416 30.5606 30.5166 30.9825L31.4658 31.9435C32.2393 32.7403 33.5283 32.7638 34.3369 31.9552L36.1065 30.1856C36.8565 29.4474 36.9151 28.3106 36.2354 27.4903C37.3369 26.3888 37.2783 25.1935 36.5283 24.3497C37.6065 23.3419 38.2158 22.0294 38.2158 20.5411C38.2158 17.6349 35.8486 15.256 32.919 15.256Z" />
      <path d="M32.919 17.002C30.9268 17.002 29.3447 18.5958 29.3447 20.5645C29.3447 22.0763 30.2354 23.377 31.6065 23.9161V29.4474C31.6065 29.588 31.6768 29.7052 31.7822 29.7989L32.7197 30.7481C32.8135 30.8653 32.9893 30.877 33.1065 30.7481L34.8877 28.9786C35.0049 28.8497 35.0049 28.6974 34.8877 28.5802L33.7861 27.4786L35.3096 25.9786C35.4151 25.8731 35.4151 25.7091 35.2861 25.5685L33.7861 24.0802C35.5088 23.377 36.4815 22.1114 36.4815 20.5645C36.4815 18.5958 34.8877 17.002 32.919 17.002ZM32.9072 18.6661C33.5166 18.6661 33.9971 19.17 33.9971 19.7677C33.9971 20.3888 33.5166 20.881 32.9072 20.881C32.2979 20.881 31.794 20.3888 31.794 19.7677C31.794 19.17 32.2861 18.6661 32.9072 18.6661Z" />
    </svg>
  );
};

export const Car = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 42 20">
      <path d="M3.15307 16.4075H6.73901V14.5208H3.25854C2.42651 14.5208 1.9812 14.0052 1.9812 13.0442V10.9583C1.9812 9.20044 3.22339 8.06372 5.4851 7.78247L11.0867 7.07935L16.1726 3.91529C18.7625 2.30982 20.3211 1.9231 24.7976 1.9231H27.5515C30.739 1.9231 33.3172 3.24732 35.532 6.32935L38.4031 7.07935C39.4812 7.34888 39.7742 7.99341 39.7742 8.89576V13.1497C39.7742 14.0052 39.3406 14.5208 38.6023 14.5208H35.6961V16.4075H38.9422C40.6297 16.4075 41.6609 15.3411 41.6609 13.6184V8.89576C41.6609 7.25513 41.1687 5.8606 38.989 5.18091L36.575 4.44263C35.0515 2.62622 32.3914 0.036377 27.5515 0.036377H24.7976C19.9695 0.036377 18.0476 0.505127 15.1648 2.30982L10.4422 5.27466L5.23901 5.90747C2.00464 6.30591 0.0944824 8.16919 0.0944824 10.9583V13.2786C0.0944824 15.2708 1.20776 16.4075 3.15307 16.4075ZM12.2234 16.4075H30.3054V14.5208H12.2234V16.4075ZM9.41089 19.0794C11.6609 19.0794 13.4187 17.3216 13.4187 15.0716C13.4187 12.8216 11.6609 11.0637 9.41089 11.0637C7.16089 11.0637 5.40307 12.8216 5.40307 15.0716C5.40307 17.3216 7.16089 19.0794 9.41089 19.0794ZM9.41089 17.1926C8.21557 17.1926 7.28979 16.2669 7.28979 15.0716C7.28979 13.8762 8.21557 12.9505 9.41089 12.9505C10.6062 12.9505 11.532 13.8762 11.532 15.0716C11.532 16.2669 10.6062 17.1926 9.41089 17.1926ZM33.0828 19.0794C35.3328 19.0794 37.0906 17.3216 37.0906 15.0716C37.0906 12.8216 35.3328 11.0637 33.0828 11.0637C30.8328 11.0637 29.075 12.8216 29.075 15.0716C29.075 17.3216 30.8328 19.0794 33.0828 19.0794ZM33.0828 17.1926C31.8875 17.1926 30.9617 16.2669 30.9617 15.0716C30.9617 13.8762 31.8875 12.9505 33.0828 12.9505C34.2781 12.9505 35.2039 13.8762 35.2039 15.0716C35.2039 16.2669 34.2781 17.1926 33.0828 17.1926Z" />
    </svg>
  );
};

export const Comment = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 34 27">
      <path d="M5.45117 19.7783H6.01367V22.8369C6.01367 23.7392 6.4707 24.3134 7.29101 24.3134C7.86523 24.3134 8.26367 24.0088 8.97851 23.3877L13.0215 19.7783H20.5449C24.0371 19.7783 25.9121 17.8564 25.9121 14.4228V5.43457C25.9121 2.00098 24.0371 0.0673828 20.5449 0.0673828H5.45117C1.95898 0.0673828 0.0839844 2.00098 0.0839844 5.43457V14.4228C0.0839844 17.8564 1.95898 19.7783 5.45117 19.7783ZM5.45117 17.8916C3.08398 17.8916 1.9707 16.6963 1.9707 14.4111V5.44629C1.9707 3.16114 3.08398 1.95411 5.45117 1.95411H20.5449C22.9004 1.95411 24.0254 3.16114 24.0254 5.44629V14.4111C24.0254 16.6963 22.9004 17.8916 20.5449 17.8916H12.9512C12.2949 17.8916 11.9668 17.997 11.5215 18.4541L7.77148 22.1689V18.7822C7.77148 18.1377 7.52539 17.8916 6.89257 17.8916H5.45117Z" />
      <path d="M17.709 22.6025H21.5059L25.4199 25.9541C26.1348 26.5634 26.5215 26.8799 27.084 26.8799C27.8926 26.8799 28.3496 26.3056 28.3496 25.415V22.6025H28.5957C31.9707 22.6025 33.7402 20.7861 33.7402 17.4697V11.1299C33.7402 7.80176 31.9707 5.98535 28.5957 5.98535H17.709C14.334 5.98535 12.5645 7.80176 12.5645 11.1299V17.4697C12.5645 20.7978 14.334 22.6025 17.709 22.6025Z" />
      <path d="M17.709 22.6025H21.5059L25.4199 25.9541C26.1348 26.5634 26.5215 26.8799 27.084 26.8799C27.8926 26.8799 28.3496 26.3056 28.3496 25.415V22.6025H28.5957C31.9707 22.6025 33.7402 20.7861 33.7402 17.4697V11.1299C33.7402 7.80176 31.9707 5.98535 28.5957 5.98535H17.709C14.334 5.98535 12.5645 7.80176 12.5645 11.1299V17.4697C12.5645 20.7978 14.334 22.6025 17.709 22.6025ZM17.7442 20.7158C15.4473 20.7158 14.4629 19.6377 14.4629 17.4463V11.1533C14.4629 8.96192 15.4473 7.87207 17.7442 7.87207H28.5723C30.8574 7.87207 31.8535 8.96192 31.8535 11.1533V17.4345C31.8535 19.6377 30.8574 20.7158 28.5723 20.7158H27.4942C26.9902 20.7158 26.5918 21.0205 26.5918 21.6767V24.747L22.6309 21.208C22.2442 20.8447 21.916 20.7158 21.4356 20.7158H17.7442Z" />
    </svg>
  );
};

export const LogOut = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 22 22">
      <path d="M4.005 10.8215C4.005 11.2902 4.33312 11.6184 4.77844 11.6184H10.1574L12.3136 11.4777L9.93469 13.5988L9.18469 14.2902C9.02063 14.4309 8.95031 14.6184 8.95031 14.8527C8.95031 15.298 9.27845 15.6145 9.72376 15.6145C9.91126 15.6145 10.1574 15.509 10.3214 15.3566L14.3527 11.4074C14.5285 11.2434 14.6222 11.0441 14.6222 10.8215C14.6222 10.6106 14.5168 10.3996 14.3527 10.2356L10.3097 6.25123C10.1339 6.07544 9.93469 6.01685 9.72376 6.01685C9.30188 6.01685 8.95031 6.32154 8.95031 6.76685C8.95031 6.97779 9.02063 7.17701 9.18469 7.34107L9.92298 8.05591L12.3019 10.1652L10.1574 10.048H4.77844C4.33312 10.048 4.005 10.3762 4.005 10.8215ZM16.2863 15.9895C16.755 15.9895 17.0832 15.673 17.0832 15.216V6.41529C17.0832 5.95826 16.755 5.63013 16.2863 5.63013C15.841 5.63013 15.5129 5.95826 15.5129 6.41529V15.216C15.5129 15.673 15.841 15.9895 16.2863 15.9895Z" />
    </svg>
  );
};

export const View = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 32 29">
      <g clip-path="url(#clip0_201_93466)">
        <path
          d="M15.9809 23.9966C25.3676 23.9966 31.8598 16.4028 31.8598 14.0356C31.8598 11.6567 25.3559 4.07471 15.9809 4.07471C6.71142 4.07471 0.090332 11.6567 0.090332 14.0356C0.090332 16.4028 6.71142 23.9966 15.9809 23.9966ZM15.9809 20.563C12.3247 20.563 9.41845 17.5982 9.41845 14.0356C9.41845 10.3794 12.3247 7.5083 15.9809 7.5083C19.602 7.5083 22.5317 10.3794 22.5317 14.0356C22.5317 17.5982 19.602 20.563 15.9809 20.563ZM15.9809 16.3911C17.2817 16.3911 18.3598 15.3247 18.3598 14.0356C18.3598 12.7349 17.2817 11.6685 15.9809 11.6685C14.6684 11.6685 13.5903 12.7349 13.5903 14.0356C13.5903 15.3247 14.6684 16.3911 15.9809 16.3911Z"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_93466">
          <rect
            width="31.7695"
            height="19.9336"
            transform="translate(0.090332 4.06299)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Trush = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 29 29">
      <g clip-path="url(#clip0_201_71865)">
        <path
          d="M10.9149 22.4687C11.3602 22.4687 11.6532 22.1875 11.6415 21.7773L11.2782 9.23828C11.2665 8.82812 10.9735 8.55859 10.5516 8.55859C10.1063 8.55859 9.81336 8.83984 9.82508 9.25L10.1766 21.7773C10.1884 22.1992 10.4813 22.4687 10.9149 22.4687ZM14.3837 22.4687C14.829 22.4687 15.1454 22.1875 15.1454 21.7773V9.25C15.1454 8.83984 14.829 8.55859 14.3837 8.55859C13.9383 8.55859 13.6337 8.83984 13.6337 9.25V21.7773C13.6337 22.1875 13.9383 22.4687 14.3837 22.4687ZM17.8641 22.4687C18.286 22.4687 18.579 22.1992 18.5907 21.7773L18.9422 9.25C18.954 8.83984 18.661 8.55859 18.2157 8.55859C17.7938 8.55859 17.5008 8.82812 17.4891 9.25L17.1376 21.7773C17.1258 22.1875 17.4188 22.4687 17.8641 22.4687ZM9.23914 5.5H11.1024V2.99219C11.1024 2.32422 11.5712 1.89062 12.2743 1.89062H16.4696C17.1727 1.89062 17.6415 2.32422 17.6415 2.99219V5.5H19.5047V2.875C19.5047 1.17578 18.4032 0.144531 16.5985 0.144531H12.1454C10.3407 0.144531 9.23914 1.17578 9.23914 2.875V5.5ZM3.91882 6.4375H24.8602C25.3407 6.4375 25.7274 6.02734 25.7274 5.54687C25.7274 5.06641 25.3407 4.66797 24.8602 4.66797H3.91882C3.45007 4.66797 3.03992 5.06641 3.03992 5.54687C3.03992 6.03906 3.45007 6.4375 3.91882 6.4375ZM9.01648 26.2422H19.7626C21.4383 26.2422 22.5633 25.1523 22.6454 23.4765L23.4657 6.21484H21.579L20.7938 23.2773C20.7704 23.9804 20.2665 24.4726 19.5751 24.4726H9.18054C8.51258 24.4726 8.00867 23.9687 7.97351 23.2773L7.14148 6.21484H5.30164L6.13367 23.4883C6.2157 25.164 7.31726 26.2422 9.01648 26.2422Z"
          fill-opacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_71865">
          <rect
            width="22.6875"
            height="28.1836"
            transform="translate(3.03992 0.144531)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Cash = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 24 24">
      <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
      <path
        fillRule="evenodd"
        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
        clipRule="evenodd"
      ></path>
      <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"></path>
    </svg>
  );
};

export const Signal = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 24 24">
      <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
    </svg>
  );
};
