export const typeOfCar = [
  { key: "sedan", label: "Sedan" },
  { key: "electric", label: "Electric" },
  { key: "hybrid", label: "Hybrid" },
];

export const availabilityStatus = [
  { key: "available", label: "Available" },
  { key: "unavailable", label: "Unavailable" },
];

export const transmission = [
  { key: "mechanic", label: "Mechanic" },
  { key: "automatic", label: "Automatic" },
];

export const typeOptions = [
  { key: "sedan", label: "Sedan" },
  { key: "electric", label: "Electric" },
  { key: "hybrid", label: "Hybrid" },
];

export const statusOptions = [
  { key: "luxury", label: "Luxury" },
  { key: "sport", label: "Sport" },
  { key: "compact", label: "Compact" },
  { key: "standard", label: "Standard" },
  { key: "minivan", label: "Minivan" },
  { key: "pickup-truck", label: "Pickup Truck" },
];

export const transmissionOptions = [
  { key: "mechanic", label: "Mechanic" },
  { key: "automatic", label: "Automatic" },
];

export const items = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "confirmed",
    label: "Confirmed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "rented",
    label: "Rented",
  },
  {
    value: "returned",
    label: "Returned",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
];
