import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Alert, Descriptions } from 'antd';

const EditRentCar = () => {
    const location = useLocation();
    const rentalId = location.pathname.split('/').pop();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({});

    const fetchRental = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}booking/${rentalId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (!response.ok) {
                setError(`Failed to fetch rent cars: ${response.status} ${response.statusText}`)
                throw new Error(`Failed to fetch rent cars: ${response.status} ${response.statusText}`);
            }

            const { data } = await response.json();

            setFormData(data);
            setSuccess("Car has been updated successfully !");


        } catch (error) {
            console.error('Error fetching rent cars:', error.message);
            setError(`Error fetching rent cars: ${error.message}`)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRental();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalId]);

    const items = [
        {
            key: '1',
            label: 'Client',
            span: 3,
            children: `${formData.billingInfo?.firstName} ${formData.billingInfo?.lastName}`,
        },
        {
            key: '2',
            label: 'Email',
            span: 3,
            children: formData.billingInfo?.email,
        },
        {
            key: '3',
            label: 'Phone number',
            span: 3,
            children: `+${formData.billingInfo?.phoneNumber}`,
        },
        {
            key: '4',
            label: 'Age',
            span: 3,
            children: formData.billingInfo?.age,
        },
        {
            key: '5',
            label: 'Passport',
            span: 3,
            children: (`${formData.billingInfo?.passport?.series || ""}${formData.billingInfo?.passport?.number}`),
        },
        {
            key: '6',
            label: 'Driver license',
            span: 3,
            children: (`${formData.billingInfo?.driverLicense?.series || ""} ${formData.billingInfo?.driverLicense?.number}`),
        },
        {
            key: '7',
            label: 'Make',
            span: 3,
            children: formData.car?.make,
        },
        {
            key: '8',
            label: 'Model',
            span: 3,
            children: formData.car?.model,
        },
        {
            key: '9',
            label: 'Color',
            span: 3,
            children: formData.car?.color,
        },
        {
            key: '10',
            label: 'Car informations',
            span: 3,
            children: (
                <div className='flex py-10'>
                    <div className='flex-1'>
                        <img src={formData.car?.imgCar?.url} alt={formData.car?.make} className='w-72 overflow-hidden' />
                    </div>
                    <div className='flex-1 border-l-2 pl-10'>
                        <ul className='flex flex-col justify-start gap-2'>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Price per day:</span>
                                <span className='ml-2 font-bold uppercase'>${formData.car?.pricePerDay}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Price per hours:</span>
                                <span className='ml-2 font-bold uppercase'>${formData.car?.pricePerHour}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Deposit:</span>
                                <span className='ml-2 font-bold uppercase'>${formData.car?.deposit}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Type:</span>
                                <span className='ml-2 font-bold uppercase'>{formData.car?.typeOfCar}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Category:</span>
                                <span className='ml-2 font-bold uppercase'>{formData.car?.category}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Transmission:</span>
                                <span className='ml-2 font-bold uppercase'>{formData.car?.transmission}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            key: '11',
            label: 'Rental informations',
            span: 3,
            children: (
                <div className='flex py-10'>
                    <div className='flex-1'>
                        <ul className='flex flex-col justify-start gap-2'>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Pick Up Date:</span>
                                <span className='ml-2 font-bold uppercase'>{new Date(formData.rentalDetails?.pickUpDate).toDateString()}</span>
                                <span className='ml-2 font-bold uppercase'>{formData.rentalDetails?.pickUpTime}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Pick Up Location:</span>
                                <span className='ml-2 font-bold uppercase'>{formData.rentalDetails?.pickUpLocation}</span>
                            </li>
                            <br />
                            <br />
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Drop Off Info:</span>
                                <span className='ml-2 font-bold uppercase'>{new Date(formData.rentalDetails?.pickUpDate).toDateString()}</span>
                                <span className='ml-2 font-bold uppercase'>{formData.rentalDetails?.pickUpTime}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Drop Off Location:</span>
                                <span className='ml-2 font-bold uppercase'>{formData.rentalDetails?.dropOffLocation !== null ? formData.rentalDetails?.dropOffLocation : formData.rentalDetails?.pickUpLocation}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='flex-1 border-l-2 pl-10'>
                        <ul className='flex flex-col justify-start gap-2'>
                            {
                                formData.options?.deliveryFees &&
                                <li className='text-copy-dark text-sm font-normal leading-normal'>
                                    <span className=''>Price for delivery:</span>
                                    <span className='ml-2 font-bold uppercase'>{formData.options?.deliveryFeesPrice}</span>
                                </li>
                            }
                            {
                                formData.options?.kidsSeat &&
                                <li className='text-copy-dark text-sm font-normal leading-normal'>
                                    <span className=''>Price for delivery:</span>
                                    <span className='ml-2 font-bold uppercase'>{formData.options?.kidsSeatPrice}</span>
                                </li>
                            }
                            {
                                formData.options?.returnLocationFees &&
                                <li className='text-copy-dark text-sm font-normal leading-normal'>
                                    <span className=''>Price for delivery:</span>
                                    <span className='ml-2 font-bold uppercase'>{formData.options?.returnLocationFeesPrice}</span>
                                </li>
                            }
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Deposit:</span>
                                <span className='ml-2 font-bold uppercase'>${formData.car?.deposit}</span>
                            </li>
                            <li className='text-copy-dark text-sm font-normal leading-normal'>
                                <span className=''>Rental type:</span>
                                <span className='ml-2 font-bold uppercase'>{formData?.rentalType}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            key: '13',
            label: 'Payment',
            span: 3,
            children: (
                <div className='flex items-center gap-5'>
                    <p className='text-copy-dark text-sm font-normal space-x-2'>
                        <span>Method: </span>
                        <span className='font-bold uppercase'>{formData.paymentMethod?.typePayment}</span>
                    </p>
                    <p className='text-copy-dark text-sm font-normal space-x-2'>
                        <span>Status: </span>
                        <span className='text-[#f50] uppercase py-2 px-5 border border-[#f50] rounded-md'>{formData.paymentMethod?.paymentStatus}</span>
                    </p>
                    <p className='text-copy-dark text-sm font-normal space-x-2'>
                        <span>Payment: </span>
                        <a href={`/payment/${formData.paymentMethod?._id}`}>
                            <span className='text-[#f50] underline uppercase py-2 px-5 rounded-md'>{formData.paymentMethod?._id}</span>
                        </a>
                    </p>
                </div>
            )
        },
        {
            key: '14',
            label: 'Review',
            children: (
                <div className='flex justify-between'>
                    <span className='py-5 px-5 rounded-lg text-primary text-4xl font-bold leading-normal uppercase'>{formData?.rentcarStatus}</span>
                    <span className='py-5 text-copy-dark text-4xl font-bold leading-normal uppercase'>${formData.totalCost}</span>
                </div>
            ),
        },
    ];

    return (
        <section className='edit-rent-car__section overflow-hidden'>
            <Alert message={success} type="success" showIcon className={`w-96 p-5 absolute top-10 ${success ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <Alert message={error} type="error" showIcon className={`w-96 p-5 absolute top-10 ${error ? "right-20" : "-right-96"} z-50 transition-all duration-300 ease-in-out`} />
            <div className="container mx-auto">
                {
                    !loading &&
                    <Descriptions bordered items={items} className='bg-white' />
                }
            </div >
        </section >
    );
};

export default EditRentCar;

