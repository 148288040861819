import { Routes, Route } from 'react-router-dom';

import App from '../App';
import Authentication from '../page/Authentication';
import Layout from '../components/sections/Layout';
import ListOfCars from '../page/ListOfCars';
import AddCar from '../page/AddCar';
import EditCar from '../page/EditCar';
import PrivateRoute from './PrivateRoute';
import ListOfAdmins from '../page/ListOfAdmins';
import AddAdmin from '../page/AddAdmin';
import EditAdmin from '../page/EditAdmin';
import Profile from '../page/Profile';
import ListOfRentCar from '../page/ListOfRentCar';
import EditRentCar from '../page/EditRentCar';
import ListOfComments from '../page/ListOfComments';
import AddComment from '../page/AddComment';
import AddRental from '../page/AddRental';
import Payment from '../page/Payment';

const RooteRoute = () => {
    return (
        <Routes>
            <Route path="/login" element={<Authentication />} />
            <Route path="/" element={<Layout />}>
                <Route index element={<PrivateRoute><App /></PrivateRoute>} />
                <Route path='/list-of-rental' element={<PrivateRoute><ListOfRentCar /></PrivateRoute>} />
                <Route path='/list-of-rental/:id' element={<PrivateRoute><EditRentCar /></PrivateRoute>} />
                <Route path='/payment/:id' element={<PrivateRoute><Payment /></PrivateRoute>} />
                <Route path='/add-rental' element={<PrivateRoute><AddRental /></PrivateRoute>} />
                <Route path='/add-car' element={<PrivateRoute><AddCar /></PrivateRoute>} />
                <Route path='/list-of-cars' element={<PrivateRoute> <ListOfCars /></PrivateRoute>} />
                <Route path='/list-of-cars/:id' element={<PrivateRoute> <EditCar /></PrivateRoute>} />
                <Route path='/add-admin' element={<PrivateRoute><AddAdmin /></PrivateRoute>} />
                <Route path='/list-of-admins' element={<PrivateRoute> <ListOfAdmins /></PrivateRoute>} />
                <Route path='/list-of-admins/:id' element={<PrivateRoute><EditAdmin /></PrivateRoute>} />
                <Route path='/list-of-comments' element={<PrivateRoute><ListOfComments /></PrivateRoute>} />
                <Route path='/add-comment' element={<PrivateRoute><AddComment /></PrivateRoute>} />
                <Route path='/profile/:id' element={<PrivateRoute><Profile /></PrivateRoute>} />
            </Route>
        </Routes>
    )
}


export default RooteRoute